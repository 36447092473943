.switch {
    position: relative;
    display: inline-flex;
    width: 44px;
    height: 24px;
    border-radius: 9999px;
    border: 2px solid transparent;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    outline: none;
  }
  
  .checked {
    background-color: #2ac244d7;
  }
  
  .unchecked {
    background-color: #00000077;
  }
  
  .handle {
    position: absolute;
    top: 50%;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    transform: translateY(-50%);
    transition: transform 0.2s ease-in-out;
  }
  
  .handleChecked {
    left: 22px;
  }
  
  .handleUnchecked {
    left: 2px;
  }
  