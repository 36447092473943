.container {
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
  }
  
  .scrollContainer {
    overflow-x: auto;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .thead {
    background-color: #f9fafb;
  }
  
  .th {
    padding: 12px;
    text-align: left;
    font-size: 12px;
    font-weight: 600;
    color: #6b7280;
    text-transform: uppercase;
  }
  
  .textRight {
    text-align: right;
  }
  
  .tbody {
    background-color: white;
  }
  
  .td {
    padding: 16px;
    white-space: nowrap;
  }
  
  .userContainer {
    display: flex;
    align-items: center;
  }
  
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 15px;
  }
  
  .userInfo {
    margin-left: 12px;
  }
  
  .userName {
    font-size: 14px;
    font-weight: 500;
    color: #111827;
  }
  
  .email , .companyName{
    font-size: 14px;
    color: #6b7280;
  }
  
  .status {
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 12px;
  }
  
  .active {
    background-color: #d1fae5;
    color: #047857;
  }
  
  .inactive {
    background-color: #fee2e2;
    color: #b91c1c;
  }
  