
.resetPasswordContainer {
    min-height: 100vh;
    background-color: #f9fafb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 6rem 1.5rem;
  }
  
  .resetPasswordHeader {
    margin: 0 auto;
    width: 100%;
    max-width: 28rem;
    text-align: center;
  }
  
  .iconWrapper {
    display: flex;
    justify-content: center;
  }
  
  .icon {
    width: 3rem;
    height: 3rem;
    color: #4f46e5;
  }
  
  .title {
    margin-top: 1.5rem;
    text-align: center;
    font-size: 1.875rem;
    font-weight: 800;
    color: #111827;
  }
  
  .subtitle {
    margin-top: 0.5rem;
    text-align: center;
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  .formContainer {
    margin-top: 2rem;
    margin: 2rem auto 0;
    width: 100%;
    max-width: 28rem;
  }
  
  .formBox {
    background-color: white;
    padding: 2rem 1rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
  }
  
  @media (min-width: 640px) {
    .formBox {
      padding: 2rem 2.5rem;
    }
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .formGroup {
    display: flex;
    flex-direction: column;
  }
  
  .formLabel {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    color: #374151;
    margin-bottom: 0.5rem;
  }
  
  .inputWrapper {
    position: relative;
  }
  
  .formInput {
    width: 100%;
    padding: 0.5rem 0.75rem;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    outline: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .formInput:focus {
    border-color: #4f46e5;
    box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
  }
  
  .togglePassword {
    position: absolute;
    right: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    color: #6b7280;
  }
  
  .errorMessage {
    margin-top: 0.5rem;
    font-size: 0.875rem;
    color: #dc2626;
  }
  
  .submitButton {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: white;
    background-color: #4f46e5;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;
  }
  
  .submitButton:hover {
    background-color: #4338ca;
  }
  
  .submitButton:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.5);
  }

  .submitButtonDisabled {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: white;
    background-color: #b1b1b3bb;    
    cursor: not-allowed !important;
    transition: background-color 0.15s ease-in-out;
  }
  
  .submitButtonDisabled:hover {    
    cursor: not-allowed !important;
  }
  
  .submitButtonDisabled:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(185, 183, 224, 0.5);
  }